import ArticleProps from '@components/ui/Article/Article.props';
import HighlightedNewsItemProps from '@components/ui/HighlightedNewsItem/HighlightedNewsItem.props';
import { EVENT_TYPES, IMAGE_SIZES } from '@constants/enums';
import { IAssetFields } from '@models/content_elements/Asset/types';
import { ELEMENT_TYPES, TContentElement } from '@models/content_elements/ContentElementTypes';
import { ITextElement } from '@models/content_elements/Text/types';
import { E_PUBLICATION_TYPES } from '@models/dynamicPages';

import { ITip, ITipPreviewFields } from 'modules/BucketListTipModule';
import { ICategoryPreviewFields } from 'modules/CategoryModule';
import {
  ICategoryOverviewResponseFields,
  ICategoryOverviewArchivePublicationFields,
} from 'modules/CategoryOverviewModule';
import {
  IMyBucketListItemSnapshot,
  IMyBucketListItemsResponse,
  IMyBucketListItemResponse,
} from 'modules/MyBucketListModule';

import { INewsArticlePreviewFields } from 'modules/NewsModule';
import { ITravelstoryPreviewFields } from 'modules/TravelstoryModule/TravelstoryModule.props';
import Strings from './StringsUtil';

class PreprUtil {
  bucketListToProps = (tip: ITipPreviewFields): IMyBucketListItemSnapshot => {
    return {
      id: tip._id,
      title: tip.title,
      excerpt: tip.excerpt ? Strings.stripHTML(tip.excerpt) : '',
      thumbnail: tip.thumbnail?.[0]?.cdn_files?.[0]?.full,
      url: `/${tip._slug.replace(/^\/|\/$/g, '')}`,
      bookmarkCount: tip._bookmarks,
      bookmarkCountEN: tip._likes,
      type: E_PUBLICATION_TYPES.BL_TIP_SINGLE,
    };
  };

  formatCategoryToBucketList = (item: ICategoryPreviewFields): IMyBucketListItemSnapshot => {
    const excerpt: ITextElement | undefined = item.excerpt.find<ITextElement>(
      ((a: TContentElement) => a.__typename === ELEMENT_TYPES.TEXT) as any,
    );

    return {
      id: item._id,
      title: item.title,
      excerpt: excerpt?.body ? Strings.stripHTML(excerpt.body) : '',
      thumbnail:
        item.thumbnail[0].cdn_files && item.thumbnail[0].cdn_files[0]
          ? item.thumbnail[0].cdn_files[0]?.full
          : '',
      url: `/${item._slug}/`,
      type: E_PUBLICATION_TYPES.CATEGORY_SINGLE,
      bookmarkCount: item._subscribes,
      bookmarkCountEN: item._votes,
    };
  };

  formatToListItemProps = (
    tip: ITipPreviewFields | ICategoryPreviewFields,
    type: E_PUBLICATION_TYPES = E_PUBLICATION_TYPES.BL_TIP_SINGLE,
    bookmarkType: EVENT_TYPES.BOOKMARK | EVENT_TYPES.SUBSCRIBE = EVENT_TYPES.BOOKMARK,
    imageSize: IMAGE_SIZES = IMAGE_SIZES.MEDIUM,
  ): IMyBucketListItemSnapshot => {
    let excerpt = '';
    let bookmarks = 0;
    let bookmarksEN = 0;

    if (tip.excerpt) {
      if (typeof tip.excerpt !== 'string') {
        excerpt =
          tip.excerpt.find<ITextElement>(
            ((a: TContentElement) => a.__typename === ELEMENT_TYPES.TEXT) as any,
          )?.body || '';
      } else {
        excerpt = tip.excerpt;
      }
    }

    switch (true) {
      case bookmarkType === EVENT_TYPES.BOOKMARK:
        bookmarks = tip._bookmarks;
        bookmarksEN = tip._likes;
        break;
      case bookmarkType === EVENT_TYPES.SUBSCRIBE:
        bookmarks = tip._subscribes;
        bookmarksEN = tip._votes;
        break;
    }

    return {
      id: tip._id,
      title: tip.title,
      excerpt: Strings.stripHTML(excerpt),
      thumbnail: this.getCorrectImage(tip.thumbnail, imageSize),
      url: `/${tip._slug.replace(/^\/|\/$/g, '')}`,
      bookmarkCount: bookmarks || 0,
      bookmarkCountEN: bookmarksEN || 0,
      type: type,
    };
  };

  TopListToProps = (tip: any, parentSlug: string): any => {
    return {
      id: tip._id,
      title: tip.title,
      excerpt: tip.subtitle ? Strings.stripHTML(tip.subtitle) : '',
      thumbnail: tip.thumbnail?.[0]?.cdn_files?.[0],
      url: `/${parentSlug}/${tip._slug.replace(/^\/|\/$/g, '')}`,
    };
  };

  mapCategoriesToProps = (
    overviews: ICategoryOverviewResponseFields[],
  ): ICategoryOverviewArchivePublicationFields => {
    const mappedCategories = overviews.reduce((categories: any, overview) => {
      if (overview.categories) {
        const mappedCategory = overview.categories.map((category) => ({
          url: category._slug,
          label: category.title,
          parent: overview._slug,
        }));

        categories.push(...mappedCategory);
      }

      return categories;
    }, []);

    return mappedCategories;
  };

  formatRestBLTips = (items: any[], locale = 'nl-NL'): ITip[] => {
    return items.map((item: any) => {
      return {
        id: item.id,
        title: item.title[locale].body,
        excerpt: Strings.stripHTML(item.items[locale].excerpt.body),
        thumbnail: item.items[locale].thumbnail.items[0].cdn_files.items[0].resized.preview,
        url: `${item.items[locale].parent_page.items[0].slug[locale]}/${item.slug[locale]}`,
      };
    });
  };

  public formatMyBucketListItems = (
    result: IMyBucketListItemsResponse,
    bookmarkType?: EVENT_TYPES.BOOKMARK | EVENT_TYPES.SUBSCRIBE,
  ): IMyBucketListItemSnapshot[] => {
    const tips = result[E_PUBLICATION_TYPES.BL_TIP_LIST]?.items ?? [];
    const categories = result[E_PUBLICATION_TYPES.CATEGORY_LIST]?.items ?? [];

    return [
      ...(tips
        ? tips.map((tip) =>
            this.formatToListItemProps(tip, E_PUBLICATION_TYPES.BL_TIP_SINGLE, bookmarkType),
          )
        : []),
      ...(categories
        ? categories.map((cat) =>
            this.formatToListItemProps(cat, E_PUBLICATION_TYPES.CATEGORY_SINGLE, bookmarkType),
          )
        : []),
    ];
  };

  public formatMyBucketListItem = (
    result: IMyBucketListItemResponse,
    bookmarkType?: EVENT_TYPES.BOOKMARK | EVENT_TYPES.SUBSCRIBE,
  ): IMyBucketListItemSnapshot | null => {
    const tip = result[E_PUBLICATION_TYPES.BL_TIP_SINGLE];
    const category = result[E_PUBLICATION_TYPES.CATEGORY_SINGLE];

    if (tip) {
      return this.formatToListItemProps(tip, E_PUBLICATION_TYPES.BL_TIP_SINGLE, bookmarkType);
    }

    if (category) {
      return this.formatToListItemProps(
        category,
        E_PUBLICATION_TYPES.CATEGORY_SINGLE,
        bookmarkType,
      );
    }

    return null;
  };

  formatTravelstory = (
    travelstory: ITravelstoryPreviewFields,
    archiveSlug: string,
    category = '',
    isPrimary = false,
  ): ArticleProps => {
    const reads = parseInt(travelstory.reads);
    let viewcount = travelstory._views ? travelstory._views : 0;

    if (!isNaN(reads)) viewcount += reads;

    return {
      thumbnail: travelstory.thumbnail?.[0].cdn_files[0],
      tag: travelstory.tag,
      title: travelstory.title,
      excerpt: travelstory.excerpt ? Strings.stripHTML(travelstory.excerpt) : '',
      header_subtitle: travelstory.header_subtitle
        ? Strings.stripHTML(travelstory.header_subtitle)
        : '',
      category: category,
      viewcount: String(viewcount),
      url: `${archiveSlug}/${travelstory._slug}/`,
      thumbnailSize: isPrimary ? IMAGE_SIZES.MEDIUM : IMAGE_SIZES.SMALL,
    };
  };

  getCorrectImage = (images: IAssetFields, imageSize: IMAGE_SIZES = IMAGE_SIZES.MEDIUM) => {
    if (images?.[0].cdn_files?.[0]) {
      return images?.[0].cdn_files?.[0][imageSize];
    }

    return '';
  };

  formatArticle = (
    article: INewsArticlePreviewFields,
    archiveSlug: string,
    calculateViews = true,
    imageSize: IMAGE_SIZES = IMAGE_SIZES.MEDIUM,
  ): HighlightedNewsItemProps => {
    const reads = parseInt(article.reads);
    let viewcount = article._views ? article._views : 0;

    if (!isNaN(reads)) viewcount += reads;

    return {
      url: `${archiveSlug}/${article._slug}`,
      viewcount: String(calculateViews ? viewcount : article._views),
      title: article.title,
      thumbnail: this.getCorrectImage(article.thumbnail, imageSize),
      created_on: article._created_on,
    };
  };

  formatArticles = (
    articles: INewsArticlePreviewFields[],
    archiveSlug: string,
    maxNumber?: number,
    calculateViews: boolean = true,
    isSmall: boolean = false,
  ): HighlightedNewsItemProps[] => {
    return (
      articles.reduce<HighlightedNewsItemProps[]>((total, item, index) => {
        if (!maxNumber || index < maxNumber) {
          let imageSize = IMAGE_SIZES.MEDIUM;

          if (index === 0) {
            imageSize = IMAGE_SIZES.FULL;
          } else if (isSmall) {
            imageSize = IMAGE_SIZES.SMALL;
          }

          total.push(this.formatArticle(item, archiveSlug, calculateViews, imageSize));
        }

        return total;
      }, []) || []
    );
  };

  mapViews = (article: INewsArticlePreviewFields) => {
    return {
      ...article,
      _views: isNaN(parseInt(article.reads))
        ? article._views
        : parseInt(article.reads) + article._views,
    };
  };
}

export default new PreprUtil();
