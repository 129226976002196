import { AssetFields } from '@models/content_elements/Asset/query';
import { CategoryBannerFields } from '@models/content_elements/CategoryBanner/query';
import { LocationFields, ContentFields } from '@models/content_elements/Content/query';
import { E_PUBLICATION_TYPES } from '@models/dynamicPages';

export const BucketListTipFields = `
{
    _id 
    _slug
    _bookmarks
    _subscribes
    _views
    _likes
    _votes
    title
    header_subtitle
    excerpt
    parent_page {
        _slug
        title
        thumbnail ${AssetFields(1920)}
    }

    show_all_related_tips	
    related_category_read_more
    related_tips_landing_page {
        title
        _slug
        parent_page {
            _slug
        }
    }
    show_category_banner
    category_banner ${CategoryBannerFields}
    related_category {
        body
    }
    


    thumbnail ${AssetFields(924)}

   

    meta_title
    meta_description
    likes
    visited
    location ${LocationFields}
    content ${ContentFields}
}
`;

export const _BucketListTipPreviewFields = `
_id 
_slug
_bookmarks
_subscribes
_views
_likes
_votes
title
header_subtitle
excerpt
likes
visited
parent_page {
    _slug
}`;

export const BucketListTipPreviewFields = `
{
    ${_BucketListTipPreviewFields}
    thumbnail ${AssetFields(350, 450)}
}
`;

export const BucketTopListPreviewFields = `
{
    ${_BucketListTipPreviewFields}
    thumbnail ${AssetFields(500, 286)}
}
`;

export const BucketListNameListFields = `
{
    _id
    _slug
    title
    parent_page {
        _slug
    }
}
`;

export const BucketListTipSlugsQuery = () => `
${E_PUBLICATION_TYPES.BL_TIP_LIST}(limit:9999) {
    items {
        _slug
    }
}
`;

export const BucketListTipQuery = (startValue: number, loopSize: number) => `
${E_PUBLICATION_TYPES.BL_TIP_LIST}(limit:${loopSize}, skip: ${startValue}) {
    items ${BucketListTipPreviewFields}
}
`;

export const BucketTopListTipQuery = (startValue: number, loopSize: number) => `
${E_PUBLICATION_TYPES.BL_TIP_LIST}(limit:${loopSize}, skip: ${startValue}) {
    items ${BucketTopListPreviewFields}
}
`;

export const BucketListTipCountQuery = () => `
${E_PUBLICATION_TYPES.BL_TIP_LIST} {
    total
}
`;
export const CategoryListTipCountQuery = () => `
${E_PUBLICATION_TYPES.CATEGORY_LIST} {
    total
}
`;

export const BucketListTipBySlugQuery = (slug: string) => `
    ${E_PUBLICATION_TYPES.BL_TIP_SINGLE} (slug: "${slug}") ${BucketListTipFields}
`;

export const BucketListTipsByTagQuery = (
  tagId: string,
  skip: number,
  limit: number,
  excludeId?: string,
) => `
    ${
      E_PUBLICATION_TYPES.BL_TIP_LIST
    } (skip: ${skip}, limit: ${limit}, where: {_tags_any: ["${tagId}"]${
  excludeId ? `, _id_nany: ["${excludeId}"]` : ''
}}) {
        total
        items ${BucketListTipPreviewFields}
    }
`;

export const BucketListTipsByIdsQuery = (ids: string[]) => `
    ${E_PUBLICATION_TYPES.BL_TIP_LIST} (limit: 9999, where: {_id_any: [${ids.map(
  (id) => `"${id}"`,
)}]}) {
        items ${BucketListTipPreviewFields}
    }
`;
export const BucketTopListTipsByIdsQuery = (ids: string[]) => `
    ${E_PUBLICATION_TYPES.BL_TIP_LIST} (limit: 9999, where: {_id_any: [${ids.map(
  (id) => `"${id}"`,
)}]}) {
        items ${BucketTopListPreviewFields}
    }
`;

export const BucketListTipsSearchCountQuery = (search: string) => `
    ${E_PUBLICATION_TYPES.BL_TIP_LIST} (where: {_search: "${search}"}) {
        total
    }
`;

export const BucketListTipsSearchQuery = (search: string, startValue: number, loopSize: number) => `
    ${E_PUBLICATION_TYPES.BL_TIP_LIST} (where: {_search: "${search}"}, limit:${loopSize}, skip: ${startValue}) {
        items ${BucketListTipPreviewFields}
    }
`;

export const BucketListTipsNameListSearchQuery = (
  search: string,
  startValue: number,
  loopSize: number,
) => `
    ${E_PUBLICATION_TYPES.BL_TIP_LIST} (where: {_search: "${search}"},  limit:${loopSize}, skip: ${startValue}) {
        items ${BucketListNameListFields}
    }
`;

export const TipPreviewByIdQuery = (id: string) => `
    ${E_PUBLICATION_TYPES.BL_TIP_SINGLE} (id: "${id}") ${BucketListTipPreviewFields}
`;

export const TipsByLocationQuery = (lat: number, lon: number, excludeId?: string) => `
${E_PUBLICATION_TYPES.BL_TIP_LIST}(where: {
    location_within_circle: {
      lat: ${lat}
      lon: ${lon}
      radius: 1000
    }
    ${excludeId ? `, _id_nany: ["${excludeId}"]` : ''}
  }, limit: 3) {
    items ${BucketListTipPreviewFields}
  }
`;
