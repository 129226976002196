export const SmallNewsletterFormFields = `
{
    terms_link {
        _slug
        title
    }

    privacy_link {
        _slug
        title
    }
}
`;
