import { UspItemFields } from '../UspItem/query';

export const UspBlockFields = `
{
    title
    button_title
    button_href
    usps ${UspItemFields}
}
`;
