import { FB_APP_ID } from '@constants/config';
import Script from 'next/script';
import React from 'react';

export const Pixel = ({ locale }: { locale?: string }) => {
  const isDP = locale === 'nl-NL';
  const GTM_ID = isDP
    ? process.env.NEXT_PUBLIC_GTM_TRACKING_ID
    : process.env.NEXT_PUBLIC_GTM_TRACKING_ID_EN;
  const AD_ID = isDP ? 'ca-pub-8656564782464834' : 'ca-pub-8656564782464834';

  return (
    <>
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          !function (e, t, n, p, o, a, i, s, c) {
            e[o] || ((i = e[o] = function () {
              i.process ? i.process.apply(i, arguments) : i.queue.push(arguments)
            }).queue = [], i.t = 1 * new Date, (s = t.createElement(n)).async = 1, s.src = "https://cdn.tracking.prepr.io/js/openpixel.min.js?t=" + 864e5 * Math.ceil(new Date / 864e5), (c = t.getElementsByTagName(n)[0]).parentNode.insertBefore(s, c))
          }(window, document, "script", 0, "opix"),
            opix("init", "${process.env.NEXT_PUBLIC_PIXEL_ACCESS_TOKEN}");
          `,
        }}
      />

      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
            `,
        }}
      />

      <Script
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        strategy="beforeInteractive"
        data-ad-client={AD_ID}
      />

      <Script
        strategy="lazyOnload"
        crossOrigin="anonymous"
        src={`https://connect.facebook.net/${locale}/sdk.js#xfbml=1&version=v10.0&appId=${FB_APP_ID}&autoLogAppEvents=1`}
        nonce="oxoZDLBz"
      />

      <Script strategy="lazyOnload" src="https://cdn.shareaholic.net/assets/pub/shareaholic.js" />

      <div id="fb-root"></div>
    </>
  );
};
