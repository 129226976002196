import { __CLIENT_SIDE__ } from '@constants/env';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useFacebookRefresh = () => {
  const router = useRouter();

  useEffect(() => {
    if (__CLIENT_SIDE__) {
      const _window: any = window;

      _window.FB?.XFBML?.parse();
    }
  }, [router.query.slug, router.query.subSlug]);
};
