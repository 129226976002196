import { SidebarFields } from 'modules/SidebarModule';
import { AssetFields } from '@models/content_elements/Asset/query';
import { AuthorFields } from '@models/content_elements/Author/query';
import { ContentFields } from '@models/content_elements/Content/query';
import { E_PUBLICATION_TYPES } from '@models/dynamicPages';
import { TravelstoryPreviewFields } from './TravelstoryModule.fields';

export const TravelStoryFields = `
{
    _id
    _slug
    _bookmarks
    _subscribes
    _likes
    _votes
    _views
    title
    excerpt
    tag
    category {
        body
    }
    about_corno_title
    about_corno_image ${AssetFields(600)}
    about_corno_text
    about_corno_link {
        _slug
    }

    additional_information_title
    additional_information_image ${AssetFields(400)}
    additional_information_text
    
    thumbnail ${AssetFields()}
    author ${AuthorFields}
    header_subtitle
    content ${ContentFields}

    reads
    meta_title
    meta_description
}
`;

// ... on Divider ${DividerFields}
export const TravelstoryPreviewsQuery = (startValue: number, loopSize: number) => `
${E_PUBLICATION_TYPES.TRAVELSTORY_LIST}(limit:${loopSize}, skip: ${startValue}) {
    items ${TravelstoryPreviewFields}
}
`;

export const TravelStoryBySlugQuery = (slug: string) => `
Travelstory (slug: "${slug}") ${TravelStoryFields}
`;

export const TravelStorySlugsQuery = () => `
${E_PUBLICATION_TYPES.TRAVELSTORY_LIST} (limit: 9999) {
    items {
        _slug
    }
}
`;

export const TravelStoriesSearchCountQuery = (search: string) => `
    ${E_PUBLICATION_TYPES.TRAVELSTORY_LIST} (where: {_search: "${search}"}) {
        total
    }
`;

export const TravelStoriesSearchQuery = (search: string, startValue: number, loopSize: number) => `
    ${E_PUBLICATION_TYPES.TRAVELSTORY_LIST} (where: {_search: "${search}"}, limit:${loopSize}, skip: ${startValue}) {
        items ${TravelstoryPreviewFields}
    }
`;

export const TravelStoryCountQuery = () => `
${E_PUBLICATION_TYPES.TRAVELSTORY_LIST} {
    total
}
`;

export const TravelStoryArchiveSlugsQuery = () => `
TravelstoryArchives {
    items {
        _slug
    }
}
`;

export const TravelStoryArchiveFields = `
{
    _id
    _slug
    title
    sidebar ${SidebarFields}
    meta_title
    meta_description
}
`;

export const TravelStoryArchiveBySlugQuery = (slug: string) => `
TravelstoryArchive(slug: "${slug}") ${TravelStoryArchiveFields}
`;

export const TravelStoryArchivesQuery = () => `
TravelstoryArchives {
    items ${TravelStoryArchiveFields}
}
`;
