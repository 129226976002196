import { BucketListTipPreviewFields } from 'modules/BucketListTipModule';
import { CategoryPreviewFields } from 'modules/CategoryModule/CategoryModule.queries';

const myBucketListFields = `
 {
   _id
   _slug
  title
  meta_title
  meta_description
 }
`;

export const myBucketListPages = () => `
  MyBucketLists {
      items ${myBucketListFields}
  } 
`;

export const myBucketListPageSlugs = () => `
  MyBucketLists {
    items {
        _slug
    }
  }
`;

export const personalItemsQuery = (key: string) => `
  BucketListItems(where : { ${key} : true } ) {
      items ${BucketListTipPreviewFields}
      total
  }
  CategoryPages(where : { ${key} : true } ) {
      items ${CategoryPreviewFields()}
      total
  }
`;

export const myBucketListItemByIdQuery = (id: string) => `
  BucketListItem(id: "${id}" ) ${BucketListTipPreviewFields}
  
  CategoryPage(id: "${id}" ) ${CategoryPreviewFields()}
`;
