import { AssetFields } from '@models/content_elements/Asset/query';
import { AuthorFields } from '@models/content_elements/Author/query';
import { CategoryBannerFields } from '@models/content_elements/CategoryBanner/query';
import { ContentFields } from '@models/content_elements/Content/query';
import { E_PUBLICATION_TYPES } from '@models/dynamicPages';
import { SidebarFields } from 'modules/SidebarModule/SidebarModule.queries';
import { NewsArticlePreviewFields } from './NewsModule.previewQuery';

export const NewsArticleFields = `
{
    title
    _id
    _slug
    _views
    thumbnail ${AssetFields(1000)}
    excerpt
    content ${ContentFields}
    author ${AuthorFields}
    related ${NewsArticlePreviewFields}
    sidebar ${SidebarFields}
    travel_tip ${CategoryBannerFields}
    reads
    meta_title
    meta_description
}
`;

export const NewsArchiveFields = `
{
    _id
    _slug
    title
    meta_title
    meta_description
}
`;

export const NewsArchivesQuery = () => `
NewsArchives {
    items ${NewsArchiveFields}  
} 
`;

export const NewsArchiveSlugsQuery = () => `
NewsArchives {
    items {
        _slug
    }
}
`;

export const NewsArticlesQuery = (skip: number, limit: number) => `
${E_PUBLICATION_TYPES.NEWS_ARTICLE_LIST}(limit:${limit}, skip: ${skip}) {
    items ${NewsArticlePreviewFields}
}
`;

export const NewsArticleBySlugQuery = (slug: string) => `
Article(slug: "${slug}") ${NewsArticleFields}
`;

export const NewsArticleSlugsQuery = () => `
Articles (limit: 9999) {
    items {
        _slug
    }
}

${NewsArchiveSlugsQuery()}
`;

export const NewsArticlesSearchCountQuery = (search: string) => `
    ${E_PUBLICATION_TYPES.NEWS_ARTICLE_LIST} (where: {_search: "${search}"}) {
        total
    }
`;

export const NewsArticlesSearchQuery = (search: string, startValue: number, loopSize: number) => `
    ${E_PUBLICATION_TYPES.NEWS_ARTICLE_LIST} (where: {_search: "${search}"}, limit:${loopSize}, skip: ${startValue}) {
        items ${NewsArticlePreviewFields}
    }
`;

export const NewsArticleCountQuery = () => `
    ${E_PUBLICATION_TYPES.NEWS_ARTICLE_LIST} {
        total
    }
`;
