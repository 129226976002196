import React, { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { enableStaticRendering } from 'mobx-react-lite';
import Head from 'next/head';
import i18next from 'i18next';
import { ToastContainer } from 'react-toastify';
import { useRouter } from 'next/router';

import { __CLIENT_SIDE__ } from '@constants/index';
import { i18NextInitializer } from '@helpers/index';
import { useFacebookRefresh, useLocale } from '@hooks/index';
import { GenericService, MailchimpService, PersonalService } from '@services/index';
import { RootStore, RootStoreContext, useStores } from '@models/RootStore/RootStore';

import 'react-toastify/dist/ReactToastify.css';
import '@src/App.scss';
import GraphqlService from '@services/GraphqlService';
import { Pixel } from '@components/layout/Pixel/Pixel';
import { AppProps } from 'next/app';

if (!__CLIENT_SIDE__) {
  enableStaticRendering(true);
}

const MyApp = ({ Component, pageProps }: AppProps) => {
  const { UserStore } = useStores();
  const locale = useLocale();
  const router = useRouter();
  const isDP = locale === 'nl-NL';

  i18NextInitializer(locale);
  useFacebookRefresh();

  useEffect(() => {
    const urlParams = new URLSearchParams(`?${router.asPath.replace(/^\/.+\?|$/g, '')}`);
    const access_token = urlParams.get('access_token');

    GraphqlService.setLocale(locale);
    PersonalService.setLocale(locale);
    GenericService.setLocale(locale);
    MailchimpService.setLocale(locale);

    UserStore.load(access_token);
  }, []);

  return (
    <I18nextProvider i18n={i18next}>
      <RootStoreContext.Provider value={RootStore}>
        <Head>
          <link rel="icon" type="image/png" href={`/favicon-${isDP ? 'D' : 'A'}P.png`} />
        </Head>

        <Pixel locale={locale} />

        <ToastContainer />

        <Component {...pageProps} />
      </RootStoreContext.Provider>
    </I18nextProvider>
  );
};

export default MyApp;
