import { REGEXP } from '@constants/index';

class StringsUtil {
  stripHTML = (html: string): string => {
    if (!html) return '';
    return html.replace(REGEXP.HTML, '').replace(/&nbsp;/g, ' ');
  };
}

export default new StringsUtil();
