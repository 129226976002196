import { AboutCornoFields } from '@models/content_elements/AboutCorno/query';
import { NewsArticleBlockFields } from '@models/content_elements/NewsArticleBlock/query';
import { SmallNewsletterFormFields } from '@models/content_elements/SmallNewsletterForm/query';
import { TextFields } from '@models/content_elements/Text/query';
import { TravelstoriesBlockFields } from '@models/content_elements/TravelstoriesBlock/query';
import { VideosBlockFields } from '@models/content_elements/VideosBlock/query';

export const SidebarFields = `
{
    elements {
        __typename
        ... on Text ${TextFields}
        ... on SmallNewsletterForm ${SmallNewsletterFormFields}
        ... on AboutCorno ${AboutCornoFields}
        ... on NewsArticlesBlock ${NewsArticleBlockFields}
        ... on VideosBlock ${VideosBlockFields}
        ... on TravelstoriesBlock ${TravelstoriesBlockFields}
    }
}
`;

export const SidebarByIdQuery = (id: string) => `
  Sidebar(id: "${id}") ${SidebarFields}
`;
