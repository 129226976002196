import { types } from 'mobx-state-tree';

import { E_PUBLICATION_TYPES } from '@models/dynamicPages';

export const MyBucketListItemModel = types.model('MyBucketListItemModel', {
  id: types.identifier,
  title: types.string,
  excerpt: types.string,
  thumbnail: types.string,
  url: types.string,
  bookmarkCount: 0,
  bookmarkCountEN: 0,
  type: types.enumeration([E_PUBLICATION_TYPES.BL_TIP_SINGLE, E_PUBLICATION_TYPES.CATEGORY_SINGLE]),
});
