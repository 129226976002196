import { createContext, useContext } from 'react';
import { Instance, SnapshotIn, types } from 'mobx-state-tree';
import { UserStore } from '@models/User/UserStore';

export const RootStoreModel = types.model({
  UserStore: UserStore,
});

export const RootStore = RootStoreModel.create({
  UserStore: {
    profile: null,
  },
});

export interface RootStoreSnapshot extends SnapshotIn<typeof RootStoreModel> {}
export interface RootStoreInstance extends Instance<typeof RootStoreModel> {}

export const RootStoreContext = createContext<RootStoreInstance>(RootStore);

export const useStores = () => {
  return useContext(RootStoreContext);
};
