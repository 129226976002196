export interface IWebhookData {
  payload: {
    id: string;
    slug: {
      [locale in ISRLocale]: string;
    };
  };
}

export interface IISRPublicationType {
  [locale: string]: IISRItems;
}

export interface IISRItems {
  items: IISRItem[] | null;
}

export enum ISRItemType {
  ARTICLE = 'Article',
  TRAVEL_STORY = 'Travelstory',
  BUCKETLIST_ITEM = 'BucketListItem',
  CATEGORY_PAGE = 'CategoryPage',
  PAGE = 'Page',
  CATEGORY_OVERVIEW = 'CategoryOverview',
}

export enum ISRLocale {
  EN_GB = 'en-GB',
  NL_NL = 'nl-NL',
}

export interface IISRItem {
  __typename: ISRItemType;
  _slug: string;
}

export interface IISRLocale {
  key: string;
  locale: ISRLocale;
}

export type itemsLocaleTransformerProps = [string, IISRItems];

export interface getItemUrlsProps extends getLocaleArchiveSlugProps {
  slug: string;
}

export interface getLocaleArchiveSlugProps {
  locale: ISRLocale;
  type: ISRItemType;
}
