import { BucketListTipsSearchCountQuery } from 'modules/BucketListTipModule';
import { CategoryPagesSearchCountQuery } from 'modules/CategoryModule/CategoryModule.queries';

export const myAccountFields = `
{
    _id
    _slug
    title
    description
    delete_account_text
}
`;

export const myAccountsQuery = () => `
    MyAccounts {
        items ${myAccountFields}
    }
`;

export const myAccountSlugs = () => `
    MyAccounts {
        items {
            _slug
        }
    }
`;

export const searchCountriesCountQuery = (search: string) => `
    ${BucketListTipsSearchCountQuery(search)}
    ${CategoryPagesSearchCountQuery(search)}
`;
