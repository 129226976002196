import { Instance, SnapshotIn, types } from 'mobx-state-tree';

export const User = types.model({
  id: types.string,
  first_name: types.string,
  last_name: types.string,
  full_name: types.string,
  email_address: types.string,
});

export interface UserInstance extends Instance<typeof User> {}
export interface UserSnapshot extends SnapshotIn<typeof User> {}
