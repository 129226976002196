enum E_STORAGE_KEYS {
  ACCESS_TOKEN = 'accessToken',
  NEWS_HEADER_IS_OPEN = 'newsHeaderIsOpen',
}

interface ITokenData {
  token: string;
  expirationDate: number;
}

class Storage {
  public getAccessToken = (): ITokenData | null => {
    const result = this.get(E_STORAGE_KEYS.ACCESS_TOKEN);

    const now = Date.now() / 1000;
    const twoHours = 7200;

    if (result && now + twoHours < result.expirationDate) {
      return result;
    }

    this.clearAccessToken();

    return null;
  };

  public setAccessToken = (token: ITokenData) => {
    return this.set(E_STORAGE_KEYS.ACCESS_TOKEN, token);
  };

  public clearAccessToken = () => {
    return this.set(E_STORAGE_KEYS.ACCESS_TOKEN, null);
  };

  public getNewsHeaderState = (): null | boolean => {
    return this.get(E_STORAGE_KEYS.NEWS_HEADER_IS_OPEN);
  };

  public setNewsHeaderState = (state: boolean) => {
    return this.set(E_STORAGE_KEYS.NEWS_HEADER_IS_OPEN, state);
  };

  private get = (key: E_STORAGE_KEYS): any | null => {
    const result = window.localStorage.getItem(key);
    if (!result) return null;

    return JSON.parse(result);
  };

  private set = (key: E_STORAGE_KEYS, value: any): void => {
    const data = JSON.stringify(value);
    window.localStorage.setItem(key, data);
  };
}

export default new Storage();
