import DESTINATIONS from './Destinations.json';
import COOKIES from './Cookies.json';
import TRAVELSTORY from './Travelstory.json';
import NEWS from './News.json';
import ACCOUNT from './Account.json';
import BUCKETLIST from './Bucketlist.json';
import NEWSLETTER from './Newsletter.json';
import AUTHENTICATION from './Authentication.json';
export default {
  DESTINATIONS,
  COOKIES,
  TRAVELSTORY,
  ACCOUNT,
  NEWS,
  BUCKETLIST,
  NEWSLETTER,
  AUTHENTICATION,
};
