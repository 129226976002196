import i18next from 'i18next';

import data from '@locales/index';
import { ISRLocale } from 'modules/ISRModule';

const i18NextInitializer = (locale?: string) => {
  i18next.init({
    lng: locale ?? ISRLocale.NL_NL,
    resources: data,
    debug: false,
    react: {
      useSuspense: true,
      bindI18n: 'languageChanged loaded',
      nsMode: 'default',
    },
  });
};

export default i18NextInitializer;
