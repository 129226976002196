import { ITipPublicationFields } from 'modules/BucketListTipModule';
import {
  ICategoryResponseFields,
  ICategoryPreviewFields,
  ICategoryPublicationFields,
} from 'modules/CategoryModule/CategoryModule.props';
import { ICategoryOverviewArchivePublicationFields } from 'modules/CategoryOverviewModule';
import {
  IForgotPasswordPageResponseFields,
  IForgotPasswordPagePublicationFields,
} from 'modules/ForgotPasswordModule';
import { IGenericPageResponseFields, IGenericPagePublicationFields } from 'modules/GenericModule';
import { ILoginResponseFields, ILoginPublicationFields } from 'modules/LoginModule';
import { IMyAccountResponseFields, IMyAccountPublicationFields } from 'modules/MyAccountModule';
import {
  IMyBucketListtResponseFields,
  IMyBucketListtPublicationFields,
} from 'modules/MyBucketListModule';
import { IMyVisitedPublicationFields } from 'modules/MyVisitedModule';
import {
  INewsArchiveResponseFields,
  INewsArchivePublicationFields,
  INewsArticlePublicationFields,
} from 'modules/NewsModule';
import { ISearchPageResponseFields, ISearchPagePublicationFields } from 'modules/SearchModule';
import {
  IManualTopListOverviewPublicationFields,
  IManualTopListPublicationFields,
} from 'modules/TopListModule';
import {
  ITravelStoryArchivePublicationFields,
  ITravelstoryPublicationFields,
} from 'modules/TravelstoryModule/TravelstoryModule.props';
import {
  IDestinationLinksOverviewPublicationFields,
  IDestinationLinksOverviewResponseFields,
} from './publications/DestinationLinksOverview/types';
import {
  IFooterLinksPublicationFields,
  IFooterLinksResponseFields,
} from './publications/FooterLinks/types';

import {
  ISiteLinksPublicationFields,
  ISiteLinksResponseFields,
} from './publications/SiteLinks/types';
import { ITopListAllPublicationFields } from './publications/TopListAll/types';
import {
  ITravelResourcePagePublicationFields,
  ITravelResourcePageResponseFields,
} from './publications/TravelResourcePage/types';

export enum E_PUBLICATION_TYPES {
  BL_TIP_SINGLE = 'BucketListItem',
  BL_TIP_LIST = 'BucketListItems',
  CATEGORY_SINGLE = 'CategoryPage',
  CATEGORY_LIST = 'CategoryPages',
  LOGIN_LIST = 'Logins',
  LOGIN_SINGLE = 'Login',
  MY_ACCOUNT_LIST = 'MyAccounts',
  MY_ACCOUNT_SINGLE = 'MyAccount',
  TRAVELSTORY_ARCHIVE_SINGLE = 'TravelstoryArchive',
  TRAVELSTORY_ARCHIVE_LIST = 'TravelstoryArchives',
  POPULAR_TRAVELSTORIES = 'Popular_Travelstorys',
  TRAVELSTORY_LIST = 'Travelstorys',
  TRAVELSTORY_SINGLE = 'Travelstory',
  GENERIC_PAGE = 'Page',
  NEWS_ARCHIVE_LIST = 'NewsArchives',
  NEWS_ARCHIVE_SINGLE = 'NewsArchive',
  NEWS_ARTICLE_LIST = 'Articles',
  POPULAR_NEWS_ARTICLES = 'Popular_Articles',
  NEWS_ARTICLE_SINGLE = 'Article',
  MY_VISITED_LIST = 'MyVisiteds',
  MY_VISITED_SINGLE = 'MyVisited',
  MY_BUCKET_LIST_LIST = 'MyBucketLists',
  MY_BUCKET_LIST_SINGLE = 'MyBucketList',
  HOME_LIST = 'Homepages',
  HOME_SINGLE = 'Homepage',
  CATEGORY_OVERVIEW = 'CategoryOverview',
  CATEGORY_OVERVIEW_ARCHIVE = 'CategoryOverviewArchive',
  SEARCH_PAGE = 'SearchPage',
  SEARCH_PAGE_LIST = 'SearchPages',
  SITE_LINKS_SINGLE = 'SiteLinks',
  FORGOT_PASSWORD_PAGE = 'ForgotPasswordPage',
  FORGOT_PASSWORD_PAGE_LIST = 'ForgotPasswordPages',
  TRAVEL_RESOURCE_PAGE = 'TravelresourcePage',
  TOP_LIST_ALL = 'TopListAll',
  TOP_LIST_ALL_LIST = 'TopListAlls',
  MANUAL_TOP_LIST = 'ManualTopList',
  MANUAL_TOP_LIST_LIST = 'ManualTopLists',
  MANUAL_TOP_LIST_OVERVIEW = 'ManualTopListOverview',
  MANUAL_TOP_LIST_OVERVIEW_LIST = 'ManualTopListOverviews',
  DESTINATION_LINKS_SINGLE = 'DestinationLinks',
  DESTINATION_LINKS_LIST = 'DestinationLinkss',
  DESTINATION_LINKS_OVERVIEW_SINGLE = 'DestinationLinksOverview',
  DESTINATION_LINKS_OVERVIEW_LIST = 'DestinationLinksOverviews',
  FOOTER_LINKS_SINGLE = 'FooterLinks',
}

export type BaseCountResponse = {
  [key in E_PUBLICATION_TYPES]: {
    total: number;
  };
};

export interface IGraphQLList<T> {
  total?: number;
  items: T[];
}

export type BasePublicationResponse = {
  [key: string]: any;
};

export interface ILoginListResponse extends BasePublicationResponse {
  [E_PUBLICATION_TYPES.LOGIN_LIST]: IGraphQLList<ILoginResponseFields>;
}

export interface ILoginSingleResponse extends BasePublicationResponse {
  [E_PUBLICATION_TYPES.LOGIN_SINGLE]: ILoginResponseFields;
}

export interface ICategoryListResponse extends BasePublicationResponse {
  [E_PUBLICATION_TYPES.CATEGORY_LIST]: IGraphQLList<ICategoryResponseFields>;
}

export interface ICategoryPreviewListResponse extends BasePublicationResponse {
  [E_PUBLICATION_TYPES.CATEGORY_LIST]: IGraphQLList<ICategoryPreviewFields>;
}

export interface IMyAccountSingleResponse extends BasePublicationResponse {
  [E_PUBLICATION_TYPES.MY_ACCOUNT_SINGLE]: IMyAccountResponseFields;
}

export interface IMyAccountListResponse extends BasePublicationResponse {
  [E_PUBLICATION_TYPES.MY_ACCOUNT_LIST]: IGraphQLList<IMyAccountResponseFields>;
}

export interface ICategorySingleResponse extends BasePublicationResponse {
  [E_PUBLICATION_TYPES.CATEGORY_SINGLE]: ICategoryResponseFields;
}
export interface IMyBucketListSingleResponse extends BasePublicationResponse {
  [E_PUBLICATION_TYPES.MY_BUCKET_LIST_SINGLE]: IMyBucketListtResponseFields;
}
export interface INewsArchiveResponse extends BasePublicationResponse {
  [E_PUBLICATION_TYPES.NEWS_ARCHIVE_LIST]: IGraphQLList<INewsArchiveResponseFields>;
}
export interface IGenericPageResponse extends BasePublicationResponse {
  [E_PUBLICATION_TYPES.GENERIC_PAGE]: IGenericPageResponseFields;
}
export interface ISearchPageResponse extends BasePublicationResponse {
  [E_PUBLICATION_TYPES.SEARCH_PAGE]: ISearchPageResponseFields;
}
export interface ISiteLinksResponse extends BasePublicationResponse {
  [E_PUBLICATION_TYPES.SITE_LINKS_SINGLE]: ISiteLinksResponseFields;
}
export interface IFooterLinksResponse extends BasePublicationResponse {
  [E_PUBLICATION_TYPES.FOOTER_LINKS_SINGLE]: IFooterLinksResponseFields;
}
export interface IDestinationLinksOverviewResponse extends BasePublicationResponse {
  [E_PUBLICATION_TYPES.DESTINATION_LINKS_OVERVIEW_SINGLE]: IDestinationLinksOverviewResponseFields;
}

export interface IForgotPasswordPageResponse extends BasePublicationResponse {
  [E_PUBLICATION_TYPES.FORGOT_PASSWORD_PAGE]: IForgotPasswordPageResponseFields;
}
export interface ITravelResourcePageResponse extends BasePublicationResponse {
  [E_PUBLICATION_TYPES.TRAVEL_RESOURCE_PAGE]: ITravelResourcePageResponseFields;
}

export interface IPublicationFields {
  [E_PUBLICATION_TYPES.CATEGORY_SINGLE]: ICategoryPublicationFields;
  [E_PUBLICATION_TYPES.LOGIN_SINGLE]: ILoginPublicationFields;
  [E_PUBLICATION_TYPES.BL_TIP_SINGLE]: ITipPublicationFields;
  [E_PUBLICATION_TYPES.MY_ACCOUNT_SINGLE]: IMyAccountPublicationFields;
  [E_PUBLICATION_TYPES.MY_BUCKET_LIST_SINGLE]: IMyBucketListtPublicationFields;
  [E_PUBLICATION_TYPES.MY_VISITED_SINGLE]: IMyVisitedPublicationFields;
  [E_PUBLICATION_TYPES.TRAVELSTORY_ARCHIVE_SINGLE]: ITravelStoryArchivePublicationFields;
  [E_PUBLICATION_TYPES.TRAVELSTORY_SINGLE]: ITravelstoryPublicationFields;
  [E_PUBLICATION_TYPES.GENERIC_PAGE]: IGenericPagePublicationFields;
  [E_PUBLICATION_TYPES.NEWS_ARCHIVE_SINGLE]: INewsArchivePublicationFields;
  [E_PUBLICATION_TYPES.NEWS_ARTICLE_SINGLE]: INewsArticlePublicationFields;
  [E_PUBLICATION_TYPES.CATEGORY_OVERVIEW_ARCHIVE]: ICategoryOverviewArchivePublicationFields;
  [E_PUBLICATION_TYPES.SEARCH_PAGE]: ISearchPagePublicationFields;
  [E_PUBLICATION_TYPES.FORGOT_PASSWORD_PAGE]: IForgotPasswordPagePublicationFields;
  [E_PUBLICATION_TYPES.TRAVEL_RESOURCE_PAGE]: ITravelResourcePagePublicationFields;
  [E_PUBLICATION_TYPES.TOP_LIST_ALL]: ITopListAllPublicationFields;
  [E_PUBLICATION_TYPES.MANUAL_TOP_LIST]: IManualTopListPublicationFields;
  [E_PUBLICATION_TYPES.MANUAL_TOP_LIST_OVERVIEW]: IManualTopListOverviewPublicationFields;
}

export interface IPublication<T extends keyof IPublicationFields> {
  type: T;
  fields: IPublicationFields[T];
  siteLinks: ISiteLinksPublicationFields;
  destinationLinks: IDestinationLinksOverviewPublicationFields;
  footerLinks: IFooterLinksPublicationFields;
}

export type TFirstLevelPublication =
  | IPublication<E_PUBLICATION_TYPES.CATEGORY_SINGLE>
  | IPublication<E_PUBLICATION_TYPES.LOGIN_SINGLE>
  | IPublication<E_PUBLICATION_TYPES.MY_ACCOUNT_SINGLE>
  | IPublication<E_PUBLICATION_TYPES.MY_BUCKET_LIST_SINGLE>
  | IPublication<E_PUBLICATION_TYPES.TRAVELSTORY_ARCHIVE_SINGLE>
  | IPublication<E_PUBLICATION_TYPES.GENERIC_PAGE>
  | IPublication<E_PUBLICATION_TYPES.NEWS_ARCHIVE_SINGLE>
  | IPublication<E_PUBLICATION_TYPES.MY_VISITED_SINGLE>
  | IPublication<E_PUBLICATION_TYPES.CATEGORY_OVERVIEW_ARCHIVE>
  | IPublication<E_PUBLICATION_TYPES.SEARCH_PAGE>
  | IPublication<E_PUBLICATION_TYPES.FORGOT_PASSWORD_PAGE>
  | IPublication<E_PUBLICATION_TYPES.TRAVEL_RESOURCE_PAGE>
  | IPublication<E_PUBLICATION_TYPES.MANUAL_TOP_LIST_OVERVIEW>;

export type TSecondLevelPublication =
  | IPublication<E_PUBLICATION_TYPES.BL_TIP_SINGLE>
  | IPublication<E_PUBLICATION_TYPES.TRAVELSTORY_SINGLE>
  | IPublication<E_PUBLICATION_TYPES.NEWS_ARTICLE_SINGLE>
  | IPublication<E_PUBLICATION_TYPES.TOP_LIST_ALL>
  | IPublication<E_PUBLICATION_TYPES.MANUAL_TOP_LIST>;

export type TGenericPublication = TFirstLevelPublication | TSecondLevelPublication;
