/* eslint-disable quote-props */
import Axios, { AxiosInstance, AxiosResponse } from 'axios';

import {
  GraphQLResponse,
  PREPR_BASE_URL_GRAPHQL,
  PREPR_BASE_URL_TRACKING,
  PREPR_BASE_URL_REST,
  PREPR_BASE_URL_WRITE,
  FORGOT_PW_EMAIL_TEMPLATE_ID,
  EVENT_TYPES,
  FORGOT_PW_EMAIL_TEMPLATE_ID_EN,
} from '@constants/index';

import { PersonalDataFormValues } from '@components/forms/PersonalDataForm/PersonalDataForm.props';
import PreprUtil from '@utils/PreprUtil';

import MailchimpService from './MailchimpService';
import { EventHelper } from '@helpers/index';
import { BaseUserResponse, UserResponse } from '@models/User/types';

import { E_PUBLICATION_TYPES, ICategoryPreviewListResponse } from '@models/dynamicPages';

import { INewsArticlesResponse, NewsArticlesSearchQuery } from 'modules/NewsModule';
import { ICategoryNameListItem } from 'modules/CategoryModule/CategoryModule.props';
import {
  CategoryPagesSearchQuery,
  CategoryPagesNamelistSearchQuery,
} from 'modules/CategoryModule/CategoryModule.queries';
import {
  BucketListTipsNameListSearchQuery,
  BucketListTipsSearchQuery,
  ITipNameListItem,
  ITipPreviewsResponse,
} from 'modules/BucketListTipModule';
import { TravelStoriesSearchQuery } from 'modules/TravelstoryModule/TravelstortModule.queries';
import { ITravelstoryPreviewsResponse } from 'modules/TravelstoryModule/TravelstoryModule.props';
import {
  ICountriesSearchCountResponseFields,
  searchCountriesCountQuery,
} from 'modules/MyAccountModule';
import {
  IMyBucketListItemResponse,
  IMyBucketListItemsResponse,
  myBucketListItemByIdQuery,
  personalItemsQuery,
} from 'modules/MyBucketListModule';
import {
  ISearchPageSearchCountResponseFields,
  searchPageSearchCountQuery,
} from 'modules/SearchModule';

export enum E_VISITED_TYPE {
  TIPS = 'e2ce2c00-2a57-41dd-8a45-8bcd49f9df2f',
  CATEGORIES = 'a73d67ba-0d12-42fa-b48c-ee03d4b21d50',
}

class GenericService {
  private restApi: AxiosInstance;
  private GraphQLApi: AxiosInstance;
  private trackingApi: AxiosInstance;
  private writeApi: AxiosInstance;
  private locale: string;

  constructor() {
    this.restApi = Axios.create({
      baseURL: PREPR_BASE_URL_REST,
    });

    this.GraphQLApi = Axios.create({
      baseURL: PREPR_BASE_URL_GRAPHQL,
      headers: {
        Authorization: String(process.env.API_AUTH_TOKEN),
      },
    });

    this.trackingApi = Axios.create({
      baseURL: PREPR_BASE_URL_TRACKING,
    });

    this.writeApi = Axios.create({
      baseURL: PREPR_BASE_URL_WRITE,
    });

    this.locale = 'nl-NL';
  }

  public setLocale = (locale: string | undefined) => {
    if (locale) {
      this.locale = locale;
      this.restApi.defaults.headers.common['Prepr-Locale'] = locale;
      this.GraphQLApi.defaults.headers.common['Prepr-Locale'] = locale;
      MailchimpService.setLocale(locale);
    }
  };

  public queryAll = async (queries: string[]) => {
    const results = await Promise.all(queries.map((query) => this.query(query)));

    return results.reduce((total, result: any) => {
      Object.assign(total, result.data.data);

      return total;
    });
  };

  public query = <T>(query: string, authToken?: string) => {
    return this.GraphQLApi.post<GraphQLResponse<T>>(
      '/graphql',
      { query: `{${query}}` },
      {
        headers: {
          ...this.getAuthHeaders(authToken).headers,
        },
      },
    );
  };

  public me = async (authToken: string): Promise<UserResponse> => {
    const result = await this.restApi.get<UserResponse>(
      '/persons/me?fields=emails',
      this.getAuthHeaders(authToken),
    );

    return result.data;
  };

  public updateMe = async (
    { password }: PersonalDataFormValues,
    authToken: string,
  ): Promise<UserResponse> => {
    const result = await this.writeApi.put<UserResponse>(
      '/persons/me',
      {
        password: {
          password,
        },
      },
      this.getAuthHeaders(authToken),
    );

    return result.data;
  };

  public login = async (email: string, password: string): Promise<UserResponse> => {
    const thirtyDays = 60 * 60 * 24 * 30;

    const response = await this.restApi.post(
      '/persons/sign_in',
      {
        email,
        password,
        ttl: Math.round(Date.now() / 1000) + thirtyDays,
      },
      this.getAuthHeaders(process.env.API_AUTH_TOKEN as string),
    );

    return response.data;
  };

  public register = async (
    email: string,
    password: string,
    subscribe: boolean,
  ): Promise<BaseUserResponse> => {
    const response = await this.restApi.post(
      '/persons',
      {
        emails: {
          items: [
            {
              email,
            },
          ],
        },
        password: {
          password,
        },
      },
      this.getAuthHeaders(process.env.API_AUTH_TOKEN as string),
    );

    if (subscribe) {
      try {
        await MailchimpService.subscribe(email, subscribe, true);
      } catch {
        // Users get registered to Mailchimp automatically but can already exist.
        // In that case the registration should just finish successfully.
      }
    }
    return response.data;
  };

  public requestMagicLink = async (email: string, redirectUrl: string) => {
    const isDP = this.locale === 'nl-NL';

    const response = await this.writeApi.post(
      '/persons/request_sign_in',
      {
        email,
        email_template: {
          id: isDP ? FORGOT_PW_EMAIL_TEMPLATE_ID : FORGOT_PW_EMAIL_TEMPLATE_ID_EN,
        },
        redirect_url: redirectUrl,
      },
      this.getAuthHeaders(process.env.API_AUTH_TOKEN as string),
    );

    return response.data;
  };

  public requestSignIn = async (accessToken: string) => {
    const response = await this.writeApi.post(
      '/persons/sign_in_with_magic?ttl=0',
      undefined,
      this.getAuthHeaders(`${accessToken.replace('#_=_', '')}`),
    );

    return response.data;
  };

  public getBucketListItem = async (id: string) => {
    const result = await this.query<IMyBucketListItemResponse>(myBucketListItemByIdQuery(id));

    return PreprUtil.formatMyBucketListItem(result.data.data);
  };

  public getBucketlist = async (authToken: string) => {
    const result = await this.query<IMyBucketListItemsResponse>(
      personalItemsQuery(EventHelper.determineQueryKey(EVENT_TYPES.BOOKMARK, this.locale)),
      authToken,
    );

    return PreprUtil.formatMyBucketListItems(result.data.data);
  };

  public getVisitedItem = async (id: string) => {
    const result = await this.query<IMyBucketListItemResponse>(myBucketListItemByIdQuery(id));

    return PreprUtil.formatMyBucketListItem(result.data.data, EVENT_TYPES.SUBSCRIBE);
  };

  public getVisited = async (authToken: string) => {
    const result = await this.query<IMyBucketListItemsResponse>(
      personalItemsQuery(EventHelper.determineQueryKey(EVENT_TYPES.SUBSCRIBE, this.locale)),
      authToken,
    );

    return PreprUtil.formatMyBucketListItems(result.data.data, EVENT_TYPES.SUBSCRIBE);
  };

  public addToBucketList = async (activityId: string, userId: string) => {
    const event = EventHelper.determineEvent(EVENT_TYPES.BOOKMARK, this.locale);

    await this.sendEvent(event, activityId, userId);
    const tip = await this.getBucketListItem(activityId);

    return tip;
  };

  public removeFromBucketList = async (activityId: string, userId: string) => {
    const event = EventHelper.determineEvent(EVENT_TYPES.UNBOOKMARK, this.locale);

    await this.sendEvent(event, activityId, userId);

    const tip = await this.getBucketListItem(activityId);
    return tip;
  };

  public addToVisited = async (activityId: string, token: string) => {
    const event = EventHelper.determineEvent(EVENT_TYPES.SUBSCRIBE, this.locale);

    await this.sendEvent(event, activityId, token);
    const tip = await this.getVisitedItem(activityId);
    return tip;
  };

  public removeFromVisited = async (activityId: string, userId: string) => {
    const event = EventHelper.determineEvent(EVENT_TYPES.UNSUBSCRIBE, this.locale);

    await this.sendEvent(event, activityId, userId);

    const tip = await this.getBucketListItem(activityId);
    return tip;
  };

  public addView = async (publicationId: string, token?: string) => {
    return await this.sendEvent(EVENT_TYPES.VIEW, publicationId, token);
  };

  private sendEvent = async (eventType: EVENT_TYPES, publicationId: string, token?: string) => {
    const user = token ? await this.me(token) : null;

    const result = await this.trackingApi.post(
      'events',
      {
        label: eventType,
        timestamp: Math.round(Date.now() / 1000),
        constraint: true,
        publication: {
          id: publicationId,
        },
        person: user
          ? {
              id: user.id,
            }
          : null,
      },
      this.getAuthHeaders(token),
    );

    return result;
  };

  private getAuthHeaders = (token?: string) => {
    return {
      headers: {
        Authorization: token || String(process.env.API_AUTH_TOKEN),
      },
    };
  };

  public getRestInstance = (): AxiosInstance => {
    return this.restApi;
  };

  private buildSearchQueryArray = (
    search: string,
    query: (search: string, startValue: number, loopSize: number) => any,
    total: number,
    loopSize: number,
  ) => {
    return new Array(Math.ceil(total / loopSize)).fill(undefined).map((_, idx) => {
      return query(search, idx * loopSize, loopSize);
    });
  };

  private reduceSearchResults = <FieldsType>(
    items: AxiosResponse<GraphQLResponse<any>>[],
    publicationType: E_PUBLICATION_TYPES,
  ) => {
    return items.reduce((total: FieldsType[], result) => {
      const data = result.data.data[publicationType].items;
      if (data) {
        total.push(...data);
      }

      return total;
    }, []);
  };

  public search = async (search: string) => {
    const loopSize = 100;

    const countResult = await this.query<ISearchPageSearchCountResponseFields>(
      searchPageSearchCountQuery(search),
    );

    const countObject: any = countResult.data.data;

    const blQueries = this.buildSearchQueryArray(
      search,
      BucketListTipsSearchQuery,
      countObject[E_PUBLICATION_TYPES.BL_TIP_LIST]?.total ?? 0,
      loopSize,
    );

    const catQueries = this.buildSearchQueryArray(
      search,
      CategoryPagesSearchQuery,
      countObject[E_PUBLICATION_TYPES.CATEGORY_LIST]?.total ?? 0,
      loopSize,
    );

    const newsQueries = this.buildSearchQueryArray(
      search,
      NewsArticlesSearchQuery,
      countObject[E_PUBLICATION_TYPES.NEWS_ARTICLE_LIST]?.total ?? 0,
      loopSize,
    );

    const tsQueries = this.buildSearchQueryArray(
      search,
      TravelStoriesSearchQuery,
      countObject[E_PUBLICATION_TYPES.TRAVELSTORY_LIST]?.total ?? 0,
      loopSize,
    );

    const results = await Promise.all([
      Promise.all(blQueries.map((query) => this.query<ITipPreviewsResponse>(query))),
      Promise.all(catQueries.map((query) => this.query<ICategoryPreviewListResponse>(query))),
      Promise.all(newsQueries.map((query) => this.query<INewsArticlesResponse>(query))),
      Promise.all(tsQueries.map((query) => this.query<ITravelstoryPreviewsResponse>(query))),
    ]);

    const mappedResults = {
      [E_PUBLICATION_TYPES.BL_TIP_LIST]: {
        items: [
          ...this.reduceSearchResults(results[0], E_PUBLICATION_TYPES.BL_TIP_LIST),
          ...this.reduceSearchResults(results[1], E_PUBLICATION_TYPES.CATEGORY_LIST),
        ],
      },
      [E_PUBLICATION_TYPES.NEWS_ARTICLE_LIST]: {
        items: this.reduceSearchResults(results[2], E_PUBLICATION_TYPES.NEWS_ARTICLE_LIST),
      },
      [E_PUBLICATION_TYPES.TRAVELSTORY_LIST]: {
        items: this.reduceSearchResults(results[3], E_PUBLICATION_TYPES.TRAVELSTORY_LIST),
      },
    };

    return mappedResults;
  };

  public searchCountries = async (search: string) => {
    const loopSize = 100;

    const countResult = await this.query<ICountriesSearchCountResponseFields>(
      searchCountriesCountQuery(search),
    );

    const countObject: any = countResult.data.data;

    const blQueries = this.buildSearchQueryArray(
      search,
      BucketListTipsNameListSearchQuery,
      countObject[E_PUBLICATION_TYPES.BL_TIP_LIST].total,
      loopSize,
    );

    const categoryQueries = this.buildSearchQueryArray(
      search,
      CategoryPagesNamelistSearchQuery,
      countObject[E_PUBLICATION_TYPES.CATEGORY_LIST].total,
      loopSize,
    );

    const results = await Promise.all([
      Promise.all(blQueries.map((query) => this.query<ITipNameListItem>(query))),
      Promise.all(categoryQueries.map((query) => this.query<ICategoryNameListItem>(query))),
    ]);

    const mappedResults = {
      [E_PUBLICATION_TYPES.BL_TIP_LIST]: {
        items: this.reduceSearchResults(results[0], E_PUBLICATION_TYPES.BL_TIP_LIST),
      },
      [E_PUBLICATION_TYPES.CATEGORY_LIST]: {
        items: this.reduceSearchResults(results[1], E_PUBLICATION_TYPES.CATEGORY_LIST),
      },
    };

    return mappedResults;
  };
}

export default new GenericService();
