import { destroy, flow, Instance, SnapshotIn, types } from 'mobx-state-tree';

import { PersonalService } from '@services/index';
import StorageHelper from '@helpers/StorageHelper';
import { UserResponse } from './types';
import { User } from './UserModel';
import { MyBucketListItemModel } from '@models/MyBucketList/MyBucketListItemModel';
import { IBucketListTipInstance } from 'modules/MyBucketListModule';

export const UserStore = types
  .model({
    profile: types.maybeNull(User),
    bucketList: types.array(MyBucketListItemModel),
    visitedTips: types.array(MyBucketListItemModel),
    isLoading: false,
    isAuthenticated: false,
    hasLoaded: false,
  })
  .actions((self) => {
    const actions: { [key: string]: any } = {
      load: flow(function* (accessToken?: string) {
        self.hasLoaded = false;
        self.isLoading = true;

        try {
          if (accessToken) {
            yield actions.magicLogin(accessToken);
          }

          const tokenData = StorageHelper.getAccessToken();

          if (tokenData) {
            self.isAuthenticated = true;

            const [user, bucketList, visitedTips] = yield Promise.all([
              PersonalService.me(),
              PersonalService.getBucketlist(),
              PersonalService.getVisitedTips(),
            ]);

            self.profile = {
              ...user,
              email_address: user.emails.items[0].email,
            };
            self.bucketList = bucketList;
            self.visitedTips = visitedTips;
          }
        } catch (e: any) {
          if (e.response?.status !== 401) {
            throw e;
          }
        } finally {
          self.isLoading = false;
          self.hasLoaded = true;
        }
      }),
      login: flow(function* (email: string, password: string, rememberMe: boolean) {
        const result: UserResponse = yield PersonalService.login(email, password, rememberMe);
        const [bucketList, visitedTips] = yield Promise.all([
          PersonalService.getBucketlist(),
          PersonalService.getVisitedTips(),
        ]);

        self.profile = {
          ...result,
          email_address: email,
        };
        self.bucketList = bucketList;
        self.visitedTips = visitedTips;
        self.isAuthenticated = true;
      }),
      magicLogin: flow(function* (accessToken: string) {
        return PersonalService.requestSignIn(accessToken);
      }),
      register: flow(function* (email: string, password: string, subscribe: boolean) {
        yield PersonalService.register(email, password, subscribe);
      }),
      logout: () => {
        StorageHelper.clearAccessToken();
        self.isAuthenticated = false;
      },
      updateProfile: flow(function* (password: string) {
        yield PersonalService.updateMe(password);
      }),
      toggleBucketList: (activityId: string) => {
        const tip = self.bucketList.find(({ id }) => {
          return id === activityId;
        });

        if (tip) {
          return actions.removeFromBucketList(tip);
        } else {
          return actions.addToBucketList(activityId);
        }
      },
      addToBucketList: flow(function* (activityId: string) {
        const tip = yield PersonalService.addToBucketList(activityId);
        self.bucketList.push(tip);
      }),
      removeFromBucketList: flow(function* (tip: IBucketListTipInstance) {
        if (tip) {
          yield PersonalService.removeFromBucketList(tip.id);
          destroy(tip);
        }
      }),
      toggleVisited: (activityId: string) => {
        const tip = self.visitedTips.find(({ id }) => {
          return id === activityId;
        });

        if (tip) {
          return actions.removeFromVisited(tip);
        } else {
          return actions.addToVisited(activityId);
        }
      },
      removeFromVisited: flow(function* (tip: IBucketListTipInstance) {
        yield PersonalService.removeFromVisited(tip.id);
        destroy(tip);
      }),
      addToVisited: flow(function* (activityId: string) {
        const result = yield PersonalService.setTipAsVisited(activityId);
        self.visitedTips.push(result);
      }),
    };

    return actions;
  })
  .views((self) => {
    const views = {
      isOnBucketList(activityId: string) {
        return self.bucketList.some(({ id }) => id === activityId);
      },
      hasVisited(activityId: string) {
        return self.visitedTips.some(({ id }) => id === activityId);
      },
    };
    return views;
  });

export interface UserStoreInstance extends Instance<typeof UserStore> {}
export interface UserStoreSnapshot extends SnapshotIn<typeof UserStore> {}
