import { MailchimpTag, MAILCHIMP_API_URL, __CLIENT_SIDE__ } from '@constants/index';
import MailchimpHelper from '@helpers/MailchimpHelper';
import { ApiService } from './ApiService';
import { Md5 } from 'ts-md5/dist/md5';

class MailchimpService extends ApiService {
  public locale = 'nl-NL';

  public authorize = () => {
    const token = process.env.MAILCHIMP_API_KEY;

    if (!token) {
      throw new Error('No Mailchimp API key found.');
    }

    this.setAccessToken(`Bearer ${token}`);
  };

  public setLocale = (locale: string | undefined) => {
    if (locale) {
      this.locale = locale;
    }
  };

  constructor() {
    super(MAILCHIMP_API_URL);

    if (!__CLIENT_SIDE__) {
      this.authorize();
    }
  }

  public subscribe = async (email: string, subscribeToNewsletter = true, isOnboarding = false) => {
    const subscriberHash = Md5.hashStr(email.toLowerCase());
    const tags = MailchimpHelper.determineTags(this.locale, subscribeToNewsletter, isOnboarding);

    const params = {
      email_address: email,
      status: isOnboarding && !subscribeToNewsletter ? 'subscribed' : 'pending',
    };

    const result = await this.api.put(`/lists/cb3258bb80/members/${subscriberHash}`, params);

    await this.addTags(email, tags);

    return result.data;
  };

  public addTags = async (email: string, tags: MailchimpTag[]) => {
    const subscriberHash = Md5.hashStr(email.toLowerCase());
    const params = {
      tags,
    };

    const result = await this.api.post(`/lists/cb3258bb80/members/${subscriberHash}/tags`, params);

    return result.data;
  };
}

export default new MailchimpService();
