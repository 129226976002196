import { AssetFields, AssetsFields } from '@models/content_elements/Asset/query';
import { ContentFields } from '@models/content_elements/Content/query';
import { TextFields } from '@models/content_elements/Text/query';
import { TravelSuggestionFields } from '@models/content_elements/TravelSuggestion/query';
import { TravelTipsElementFields } from '@models/content_elements/TravelTips/query';
import { E_PUBLICATION_TYPES } from '@models/dynamicPages';

export const CategoryNameListFields = `
    {
        _id
        _slug
        title
    }
`;

export const CategoryPageBySlugQuery = (slug: string) => `
    CategoryPage (slug: "${slug}") {
        _id
        _slug
        _bookmarks
        _subscribes
        _views
        _likes
        _votes
        title
        subtitle
        thumbnail ${AssetFields()}
        excerpt {
            __typename
            
            ... on Text ${TextFields}

            ... on Assets ${AssetsFields}
        }

        category {
            body
        }

        sidebar ${TravelTipsElementFields}

        travel_suggestion_element ${TravelSuggestionFields}
        
        content ${ContentFields}

        meta_title
        meta_titel
        meta_description
    }
`;

export const CategoryPreviewFields = (width?: number, height?: number) => `
{
    _id
    _slug
    _bookmarks
    _subscribes
    _views
    _likes
    _votes
    title
    subtitle
    thumbnail ${AssetFields(width, height)}
    excerpt {
        __typename
        
        ... on Text ${TextFields}

        ... on Assets ${AssetsFields}
    }

    category {
        body
    }
}
`;

export const CategoryPageSlugsQuery = () => `
${E_PUBLICATION_TYPES.CATEGORY_LIST} (limit: 9999) {
    items {
        _slug
    }
} 
`;

export const CategoryPagePreviewQuery = (startValue: number, loopSize: number) => `
${E_PUBLICATION_TYPES.CATEGORY_LIST}(limit:${loopSize}, skip: ${startValue}) {
    items ${CategoryPreviewFields(350, 450)}
}
`;
export const CategoryPageToplistPreviewQuery = (startValue: number, loopSize: number) => `
${E_PUBLICATION_TYPES.CATEGORY_LIST}(limit:${loopSize}, skip: ${startValue}) {
    items ${CategoryPreviewFields(500, 286)}
}
`;

export const CategoryPagesSearchQuery = (search: string, startValue: number, loopSize: number) => `
    ${
      E_PUBLICATION_TYPES.CATEGORY_LIST
    } (where: {_search: "${search}"}, limit:${loopSize}, skip: ${startValue}) {
        items ${CategoryPreviewFields(350, 450)}
    }
`;

export const CategoryPagesNamelistSearchQuery = (
  search: string,
  startValue: number,
  loopSize: number,
) => `
    ${E_PUBLICATION_TYPES.CATEGORY_LIST} (where: {_search: "${search}"}, limit:${loopSize}, skip: ${startValue}) {
        items ${CategoryNameListFields}
    }
`;

export const CategoryPagesCountQuery = () => `
    ${E_PUBLICATION_TYPES.CATEGORY_LIST} {
        total
    }
`;

export const CategoryPagesSearchCountQuery = (search: string) => `
    ${E_PUBLICATION_TYPES.CATEGORY_LIST} (where: {_search: "${search}"}) {
        total
    }
`;
