import { MailchimpTag, MAILCHIMP_TAGS, MAILCHIMP_TAG_STATUS } from '@constants/index';

class MailchimpHelper {
  public determineTags = (
    locale: string,
    subscribe = true,
    isOnboarding = false,
  ): MailchimpTag[] => {
    const isDP = locale === 'nl-NL';
    const tags = [];

    if (isOnboarding) {
      tags.push({
        name: isDP ? MAILCHIMP_TAGS.ONBOARDING : MAILCHIMP_TAGS.ONBOARDING_EN,
        status: MAILCHIMP_TAG_STATUS.ACTIVE,
      });
    }

    if (subscribe) {
      tags.push({
        name: isDP ? MAILCHIMP_TAGS.NEWSLETTER : MAILCHIMP_TAGS.NEWSLETTER_EN,
        status: MAILCHIMP_TAG_STATUS.ACTIVE,
      });
    }

    return tags;
  };
}

export default new MailchimpHelper();
