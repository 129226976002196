import { AssetFields } from '@models/content_elements/Asset/query';

export const TravelstoryPreviewFields = `
{
    _id
    _slug
    _bookmarks
    _subscribes
    _likes
    _votes
    _views
    title
    excerpt
    header_subtitle
    tag
    category {
        body
    }
    thumbnail ${AssetFields(900)}
    reads
}
`;
