import DOMAIN from './domain';
import NAVIGATION from './navigation';
import COMPONENTS from './components';
import GENERIC from './generic.json';
import ERROR from './error.json';
import VALIDATION from './validation.json';
import ROUTES from './routes.json';

const data = {
  translation: {
    DOMAIN,
    NAVIGATION,
    COMPONENTS,
    GENERIC,
    ERROR,
    VALIDATION,
    ROUTES,
  },
};

export default data;
