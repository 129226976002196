import { BucketListTipsSearchCountQuery } from 'modules/BucketListTipModule';
import { CategoryPagesSearchCountQuery } from 'modules/CategoryModule/CategoryModule.queries';
import { NewsArticlesSearchCountQuery } from 'modules/NewsModule';
import { SidebarFields } from 'modules/SidebarModule/SidebarModule.queries';
import { TravelStoriesSearchCountQuery } from 'modules/TravelstoryModule/TravelstortModule.queries';

export const searchPageFields = `
{
    _id
    _slug
    title
    sidebar ${SidebarFields}
}
`;

export const searchPagesQuery = () => `
    SearchPages {
        items ${searchPageFields}
    } 
`;

export const searchPageSlugsQuery = () => `
    SearchPages {
        items {
            _slug
        }
    }
`;

export const searchPageSearchCountQuery = (search: string) => `
    ${BucketListTipsSearchCountQuery(search)}
    ${CategoryPagesSearchCountQuery(search)}
    ${TravelStoriesSearchCountQuery(search)}
    ${NewsArticlesSearchCountQuery(search)}
`;
