import TRAVELSTORY_LIST from './TravelstoryList.json';
import SIDEBAR_VIDEO_LIST from './SidebarVideoList.json';
import SIDEBAR_STORY_LIST from './SidebarStoryList.json';
import SIDEBAR_HIGHLIGHTED_LIST from './SidebarHighlightedList.json';
import NEWS_LIST from './NewsList.json';
import HIGHLIGHTED_TOP_LIST from './HighlightedTopList.json';
import BUCKET_LIST_BLOCK from './BucketListBlock.json';
import NEWSLETTER_SIGNUP_BLOCK from './NewsletterSignupBlock.json';
import TABLE_OF_CONTENTS from './TableOfContents.json';

import ALLTIME_TOP_NEWS_LIST from './AllTimeTopNewsList.json';
import HIGHLIGHTED_NEWS_LIST from './HighlightedNewsList.json';

export default {
  TRAVELSTORY_LIST,
  SIDEBAR_VIDEO_LIST,
  SIDEBAR_STORY_LIST,
  SIDEBAR_HIGHLIGHTED_LIST,
  NEWS_LIST,
  HIGHLIGHTED_TOP_LIST,
  BUCKET_LIST_BLOCK,
  NEWSLETTER_SIGNUP_BLOCK,
  TABLE_OF_CONTENTS,
  ALLTIME_TOP_NEWS_LIST,
  HIGHLIGHTED_NEWS_LIST,
};
