import { AssetFields } from '../Asset/query';
export const TravelSuggestionFields = ` 
{
    display_title
    description
    button_url
    button_label
    background_image ${AssetFields(800, 300)}
}
`;
