export enum EventListeners {
  USERSTORE_DONE_LOADING = 'UserStoreDoneLoading',
}

export enum MAILCHIMP_TAGS {
  NEWSLETTER = 'Nieuwsbrief',
  NEWSLETTER_EN = 'Nieuwsbrief-ENG',
  ONBOARDING = 'Onboarding',
  ONBOARDING_EN = 'Onboarding-ENG',
}

export enum MAILCHIMP_TAG_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum EVENT_TYPES {
  VIEW = 'View',
  BOOKMARK = 'Bookmark',
  BOOKMARK_EN = 'Like',
  UNBOOKMARK = 'Unbookmark',
  UNBOOKMARK_EN = 'Unlike',
  SUBSCRIBE = 'Subscribe',
  SUBSCRIBE_EN = 'Vote',
  UNSUBSCRIBE = 'Unsubscribe',
  UNSUBSCRIBE_EN = 'Unvote',
}

export enum IMAGE_SIZES {
  SMALL = 'small',
  MEDIUM = 'medium',
  FULL = 'full',
}

export enum NavbarTabsTypes {
  NONE = 'NONE',
  TRAVELSTORIES = 'TRAVELSTORIES',
  TOPLISTS = 'TOPLISTS',
  WILDLIFE = 'WILDLIFE',
  DESTINATIONS = 'DESTINATIONS',
  NEWS = 'NEWS',
}

export enum ADVERTISEMENT_TYPE {
  SIDEBAR,
  ARTICLE,
}

export enum ADVERTISEMENT_SITE {
  DP,
  AP,
}

export enum ADVERTISEMENT_DEVICE {
  DESKTOP_AND_MOBILE = 'both',
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}
