import { AssetFields } from '@models/content_elements/Asset/query';
export const NewsArticlePreviewFields = `
{
    title
    _slug
    _views
    _created_on
    thumbnail ${AssetFields(750, 500)}
    excerpt
    reads
}
`;
