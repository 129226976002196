import { EVENT_TYPES } from '@constants/index';

class EventHelper {
  public determineEvent = (event: EVENT_TYPES, locale: string): EVENT_TYPES => {
    const isDP = locale === 'nl-NL';

    switch (event) {
      case EVENT_TYPES.BOOKMARK:
        return isDP ? EVENT_TYPES.BOOKMARK : EVENT_TYPES.BOOKMARK_EN;
      case EVENT_TYPES.SUBSCRIBE:
        return isDP ? EVENT_TYPES.SUBSCRIBE : EVENT_TYPES.SUBSCRIBE_EN;
      case EVENT_TYPES.UNBOOKMARK:
        return isDP ? EVENT_TYPES.UNBOOKMARK : EVENT_TYPES.UNBOOKMARK_EN;
      case EVENT_TYPES.UNSUBSCRIBE:
        return isDP ? EVENT_TYPES.UNSUBSCRIBE : EVENT_TYPES.UNSUBSCRIBE_EN;
    }

    return event;
  };

  public determineQueryKey = (event: EVENT_TYPES, locale: string): string => {
    const isDP = locale === 'nl-NL';

    switch (event) {
      case EVENT_TYPES.BOOKMARK:
        return isDP ? '_personalize_bookmarked' : '_personalize_liked';
      case EVENT_TYPES.SUBSCRIBE:
        return isDP ? '_personalize_subscribed' : '_personalize_voted';
    }

    return '';
  };
}

export default new EventHelper();
