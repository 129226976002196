import axios, { AxiosResponse, AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

import { __DEV__, __TEST__ } from '@constants/index';

interface RetrySubscriber {
  onSuccess: () => void;
  onFailure: (e: AxiosError) => void;
}

export class ApiService {
  protected api: AxiosInstance;
  expiredTokenCode = 401;
  private isAlreadyFetchingAccessToken: boolean;
  private subscribers: Array<RetrySubscriber>;
  protected baseUrl: string;

  public authorize?: (tokens?: any) => any;
  protected refreshToken?: () => Promise<any>;
  protected addCustomInterceptors?: () => void;

  constructor(baseUrl: string) {
    this.isAlreadyFetchingAccessToken = false;
    this.subscribers = [];
    this.baseUrl = baseUrl;
    this.api = axios.create({
      baseURL: baseUrl,
    });
    this.api.defaults.headers.post['Content-Type'] = 'application/json';
    this.api.defaults.headers.put['Content-Type'] = 'application/json';
    this.addInterceptors();
  }

  public getAxiosInstance() {
    return this.api;
  }

  protected setAccessToken = (token: string) => {
    this.api.defaults.headers.common.Authorization = token;
  };

  public setPreview = (preview = false) => {
    if (preview && process.env.API_PREVIEW_TOKEN) {
      this.setAccessToken(process.env.API_PREVIEW_TOKEN);
    }
  };

  private onAccessTokenFetched() {
    this.subscribers = this.subscribers.filter((subscriber) => subscriber.onSuccess());
  }

  private onRefreshFailed(e: AxiosError) {
    this.subscribers = this.subscribers.filter((subscriber) => subscriber.onFailure(e));
  }

  private addSubscriber({ onSuccess, onFailure }: RetrySubscriber) {
    this.subscribers.push({ onSuccess, onFailure });
  }

  private logResponse(response: AxiosResponse, isError: boolean) {
    if (!__DEV__) return;
    console.groupCollapsed(
      `%c ${isError ? '[ERROR] ' : ''}${response?.config?.method?.toUpperCase()} ${
        response.request.responseURL
      }`,
      `color: ${isError ? '#D11020' : '#30BF89'}`,
    );
    console.log('Request data: ', response.config.data);
    console.log('Request headers: ', response.config.headers);
    console.log('Response status: ', response.status);
    console.log('Response status text: ', response.statusText);
    console.log('Response data: ', response.data);

    console.groupEnd();
  }

  protected refreshAndRetry = async (originalRequest: AxiosRequestConfig, error: any) => {
    if (!this.refreshToken) {
      return Promise.reject(error);
    }

    const retry = new Promise((resolve) => {
      this.addSubscriber({
        onSuccess: () => {
          if (originalRequest.headers) {
            originalRequest.headers.Authorization = this.api.defaults.headers.common.Authorization;
          }
          resolve(this.api(originalRequest));
        },
        onFailure: (e) => {
          Promise.reject(e);
        },
      });
    });

    if (!this.isAlreadyFetchingAccessToken) {
      this.isAlreadyFetchingAccessToken = true;

      try {
        await this.refreshToken();
        this.onAccessTokenFetched();
      } catch (e: any) {
        this.onRefreshFailed(e);
      } finally {
        this.isAlreadyFetchingAccessToken = false;
      }
    }

    return retry;
  };

  private addInterceptors() {
    if (this.addCustomInterceptors) {
      this.addCustomInterceptors();
    }
    this.api.interceptors.response.use(
      (response) => response,
      async (error) => {
        const { config, response } = error;
        const originalRequest = config;

        if (response && response.status === this.expiredTokenCode) {
          return this.refreshAndRetry(originalRequest, error);
        }

        return Promise.reject(error);
      },
    );

    if (__DEV__ && !__TEST__) {
      this.api.interceptors.request.use(
        (response: any) => {
          return response;
        },
        (error: AxiosError) => Promise.reject(error),
      );

      this.api.interceptors.response.use(
        (response: AxiosResponse) => {
          this.logResponse(response, false);

          return response;
        },
        (error: AxiosError) => {
          console.warn(error.message);
          if (error.response) {
            this.logResponse(error.response, true);
          }
          return Promise.reject(error);
        },
      );
    }
  }
}
