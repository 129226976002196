export const AssetFields = (width?: number, height?: number) => {
  const mediumModifier = 0.5;
  const smallModifier = 1 / 3;

  let assetSize = ``;
  let mediumSize = ``;
  let smallSize = ``;

  if (width && height) {
    assetSize += `(width: ${width}, height: ${height})`;
    mediumSize += `(width: ${Math.floor(width * mediumModifier)}, height: ${Math.floor(
      height * mediumModifier,
    )})`;
    smallSize += `(width: ${Math.floor(width * smallModifier)}, height: ${Math.floor(
      height * smallModifier,
    )})`;
  } else if (width) {
    assetSize += `(width: ${width})`;
    mediumSize += `(width: ${Math.floor(width * mediumModifier)})`;
    smallSize += `(width: ${Math.floor(width * smallModifier)})`;
  } else if (height) {
    assetSize += `(height: ${height})`;
    mediumSize += `(height: ${Math.floor(height * mediumModifier)})`;
    smallSize += `(height: ${Math.floor(height * smallModifier)})`;
  }

  return `
{
  _type
  name
  description
  author
  caption
  cdn_files {
    _id
    full: url ${assetSize}
    medium: url ${mediumSize}
    small: url ${smallSize}
  }
}
`;
};

export const AssetsFields = `
{
    items ${AssetFields(1920)}
} 
`;
