import { __CLIENT_SIDE__ } from '@constants/index';
import { Storage } from '@helpers/index';
import { UserResponse } from '@models/User/types';
import { IMyBucketListItemSnapshot } from 'modules/MyBucketListModule';
import { ISearchPageSearchResponseFields } from 'modules/SearchModule';
import { ApiService } from './ApiService';

class PersonalService extends ApiService {
  public authorize = () => {
    const tokenData = Storage.getAccessToken();

    if (tokenData) {
      this.setAccessToken(tokenData?.token);
    }
  };

  constructor() {
    super('/api');

    if (__CLIENT_SIDE__) {
      this.authorize();
    }
  }

  public setLocale = (locale: string | undefined) => {
    if (locale) {
      this.api.defaults.headers.common['Prepr-Locale'] = locale;
    }
  };

  public login = async (
    email: string,
    password: string,
    rememberMe: boolean,
  ): Promise<UserResponse> => {
    const response = await this.api.post<UserResponse>('/auth/sign_in/', {
      email,
      password,
    });

    const accessToken = response.data.access_token;
    this.setAccessToken(accessToken.access_token);

    const now = Date.now() / 1000;

    let expirationDate = Math.round(now) + accessToken.expires_in;

    if (!rememberMe) {
      const twoDays = 172800;

      expirationDate = Math.round(now) + twoDays;
    }

    Storage.setAccessToken({
      token: accessToken.access_token,
      expirationDate: expirationDate,
    });

    return response.data;
  };

  public register = async (
    email: string,
    password: string,
    subscribe: boolean,
  ): Promise<UserResponse> => {
    const response = await this.api.post<UserResponse>('/auth/register/', {
      email,
      password,
      subscribe,
    });

    return response.data;
  };

  public forgotPassword = async (email: string, redirectUrl: string) => {
    const response = await this.api.post('/auth/forgot_password/', {
      email,
      redirectUrl,
    });

    return response.data;
  };

  public requestSignIn = async (accessToken: string) => {
    const response = await this.api.post('/auth/request_sign_in/', {
      accessToken,
    });

    const token = response.data.access_token;

    const now = Date.now() / 1000;
    const twoDays = 172800;
    const expirationDate = Math.round(now) + twoDays;

    this.setAccessToken(token.access_token);

    Storage.setAccessToken({
      token: token.access_token,
      expirationDate: expirationDate,
    });
  };

  public me = async (): Promise<UserResponse> => {
    const response = await this.api.get('/users/me/');

    return response.data;
  };

  public updateMe = async (password: string): Promise<UserResponse> => {
    const response = await this.api.put('/users/me/', { password });

    return response.data;
  };

  public getBucketlist = async () => {
    const result = await this.api.get('/users/me/bucketlist/');

    if (!result.data) {
      return [];
    }

    return result.data;
  };

  public addToBucketList = async (activityId: string): Promise<IMyBucketListItemSnapshot> => {
    const response = await this.api.post('/users/me/bucketlist/', {
      activityId,
    });

    return response.data;
  };

  removeFromBucketList = async (activityId: string) => {
    const response = await this.api.delete('/users/me/bucketlist/', {
      params: {
        activityId,
      },
    });

    return response.data;
  };

  removeFromVisited = async (activityId: string) => {
    const response = await this.api.delete('/users/me/visited/', {
      params: {
        activityId,
      },
    });

    return response.data;
  };

  public addView = async (activityId: string): Promise<any> => {
    const response = await this.api.post('/view/', {
      activityId,
    });

    return response.data;
  };

  public setTipAsVisited = async (activityId: string): Promise<IMyBucketListItemSnapshot> => {
    const response = await this.api.post('/users/me/visited/', {
      activityId,
    });

    return response.data;
  };

  public getVisitedTips = async () => {
    const response = await this.api.get('/users/me/visited/');

    return response.data;
  };

  public search = async (search: string): Promise<ISearchPageSearchResponseFields> => {
    const response = await this.api.get(`/search/?q=${search}`);

    return response.data;
  };

  public newsletterSubscribe = async (email: string) => {
    const params = {
      email_address: email,
    };

    const result = await this.api.post('/newsletter/', params);

    return result.data;
  };

  public searchCountries = async (search: string) => {
    const response = await this.api.get(`/searchCountries/?q=${search}`);

    return response.data;
  };
}

export default new PersonalService();
