import { AssetFields } from '../Asset/query';
import { ResourceFields } from '../Resource/query';

export const SponsoredTraveltipBlockFields = `
{
  left_title
  left_image ${AssetFields(500)}
  left_text
  left_link ${ResourceFields}
  right_title
  right_image ${AssetFields(500)}
  right_text
  right_link ${ResourceFields}
}
`;
