import { AdvertisementHalfFields } from '../AdvertisementHalf/query';
import { AssetsFields } from '../Asset/query';
import { BucketlistHeaderFields } from '../BucketlistHeader/query';
import { ContactBlockFields } from '../ContactBlock/query';
import { ELEMENT_TYPES } from '../ContentElementTypes';
import { DividerFields } from '../Divider/query';
import { FacebookPostFields } from '../FacebookPost/query';
import { FaqBlockFields } from '../FaqBlock/query';
import { HTMLCodeFields } from '../HTMLCode/query';
import { InlineImageFields } from '../InlineImage/query';
import { InstagramFields } from '../InstagramPost/query';
import { NearbyTipsFields } from '../NearbyTips/query';
import { PartnersFields } from '../Partners/query';
import { PhotoAlbumFields } from '../PhotoAlbum/query';
import { QuoteFields } from '../Quote/query';
import { ResourceLinkFields } from '../ResourceLink/query';
import { SponsoredTraveltipBlockFields } from '../SponsoredTraveltipBlock/query';
import { TextFields } from '../Text/query';
import { TravelResourceGroupFields } from '../TravelResourceGroup/query';
import { TravelStoryElementFields } from '../TravelStory/query';
import { TravelSuggestionFields } from '../TravelSuggestion/query';
import { TravelTipsElementFields } from '../TravelTips/query';
import { UspBlockFields } from '../UspBlock/query';
import { YoutubePostFields } from '../YoutubePost/query';

export const ContentFields = `
{
    __typename

   ... on ${ELEMENT_TYPES.TEXT} ${TextFields}

  ... on ${ELEMENT_TYPES.ASSETS} ${AssetsFields}

  ... on ${ELEMENT_TYPES.YOUTUBE} ${YoutubePostFields}

   ...on ${ELEMENT_TYPES.TRAVEL_STORY} ${TravelStoryElementFields}

  ... on ${ELEMENT_TYPES.NEARBY_TIPS} ${NearbyTipsFields}

   ... on ${ELEMENT_TYPES.TRAVEL_SUGGESTION} {
       travel_suggestion ${TravelSuggestionFields}
   }

   ... on ${ELEMENT_TYPES.DIVIDER} ${DividerFields}

   ... on ${ELEMENT_TYPES.PHOTO_ALBUM} ${PhotoAlbumFields}

   ... on ${ELEMENT_TYPES.INLINE_IMAGE} ${InlineImageFields}

   ... on ${ELEMENT_TYPES.PARTNERS} ${PartnersFields}

   ... on ${ELEMENT_TYPES.FAQ_BLOCK} ${FaqBlockFields}

   ... on ${ELEMENT_TYPES.CONTACT_BLOCK} ${ContactBlockFields}

   ... on ${ELEMENT_TYPES.USP_BLOCK} ${UspBlockFields}

   ... on ${ELEMENT_TYPES.BUCKETLIST_HEADER} ${BucketlistHeaderFields}

   ... on ${ELEMENT_TYPES.TRAVEL_RESOURCE_GROUP} ${TravelResourceGroupFields}

   ... on ${ELEMENT_TYPES.SPONSORED_TRAVELTIP_BLOCK} ${SponsoredTraveltipBlockFields}
   
   ... on ${ELEMENT_TYPES.RESOURCE_LINK} ${ResourceLinkFields}
   
   ... on ${ELEMENT_TYPES.TRAVEL_TIPS} {
        travel_tip ${TravelTipsElementFields}
   }
   ... on ${ELEMENT_TYPES.QUOTE} ${QuoteFields}
   
   ... on ${ELEMENT_TYPES.ADVERTISEMENT_HALF} ${AdvertisementHalfFields}

   ... on ${ELEMENT_TYPES.HTML_CODE} ${HTMLCodeFields}

   ... on ${ELEMENT_TYPES.FACEBOOK_POST} ${FacebookPostFields}

   ... on ${ELEMENT_TYPES.INSTAGRAM_POST} ${InstagramFields}
} 
`;

export const LocationFields = `
{
  latitude
  longitude
}
`;
