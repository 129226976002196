import { PREPR_BASE_URL_GRAPHQL } from '@constants/config';
import compress from 'graphql-query-compress/lib/graphql-query-compress.browser.js';
import { GraphQLResponse } from '@constants/types';
import { ApiService } from './ApiService';
import {
  BaseCountResponse,
  BasePublicationResponse,
  E_PUBLICATION_TYPES,
} from '@models/dynamicPages';

class ResourceService extends ApiService {
  locale?: string;

  public authorize = () => {
    this.api.defaults.headers.common.Authorization = String(
      process.env.API_AUTH_TOKEN || process.env.NEXT_PUBLIC_API_AUTH_TOKEN,
    );
  };

  constructor() {
    super(PREPR_BASE_URL_GRAPHQL);
    this.authorize();
  }

  public setLocale = (locale: string | undefined) => {
    if (locale) {
      this.locale = locale;
      this.api.defaults.headers.common['Prepr-Locale'] = locale;
    }
  };

  public queryAll = async (queries: string[]) => {
    const results = await Promise.all(queries.map((query) => this.query(query)));

    return results.reduce((total, result) => {
      Object.assign(total, result.data.data);

      return total;
    }, {});
  };

  public operation = <T>(operation: string) => {
    return this.api.post<GraphQLResponse<T>>(
      '/graphql',
      JSON.stringify({ query: compress(operation) }),
    );
  };

  public query = <T>(query: string) => {
    return this.operation<T>(`query {${query}}`);
  };

  public get = (url: string) => {
    return this.api.get(url);
  };

  public getAllPublications = async <
    CountType extends BaseCountResponse,
    ResponseType extends BasePublicationResponse,
    FieldsType,
  >(
    countQuery: () => string,
    publicationQuery: (startValue: number, loopSize: number) => string,
    publicationType: E_PUBLICATION_TYPES,
  ) => {
    const loopSize = 100;

    const totalItemQuery = await this.query<CountType>(countQuery());
    const totalItems = totalItemQuery.data.data[publicationType].total;

    const queries = new Array(Math.ceil(totalItems / loopSize)).fill(undefined).map((_, idx) => {
      return publicationQuery(idx * loopSize, loopSize);
    });

    const results = await Promise.all(queries.map((query) => this.query<ResponseType>(query)));
    return results.reduce((total: FieldsType[], result) => {
      const data = result.data.data[publicationType].items;
      if (data) {
        total.push(...data);
      }

      return total;
    }, []);
  };
}

export default new ResourceService();
