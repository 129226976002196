export const PREPR_BASE_URL_GRAPHQL = 'https://graphql.prepr.io'; // https://api7backend.prepr.io
export const PREPR_BASE_URL_REST = 'https://cdn.prepr.io';
export const PREPR_BASE_URL_TRACKING = 'https://tracking.prepr.io';
export const PREPR_BASE_URL_WRITE = 'https://api.eu1.prepr.io';
export const MAILCHIMP_API_URL = 'https://us1.api.mailchimp.com/3.0';

export const FORGOT_PW_EMAIL_TEMPLATE_ID = 'df138c0d-2608-4181-bf4b-4c9a524c4513';
export const FORGOT_PW_EMAIL_TEMPLATE_ID_EN = '574267c3-ca93-4cca-84ae-8d48aea1248f';

export const ONBOARDING_EMAIL_TEMPLATE_ID = '72be322e-d086-4e2a-aa0b-78ec80c83afa';
export const ONBOARDING_EMAIL_TEMPLATE_ID_EN = 'c0f57858-1f2e-4219-b7ac-73bc74cb1bc0';

export const FB_LOGIN_URL = 'https://api.eu1.prepr.io/sign_in/facebook/257685902519321';
export const GOOGLE_LOGIN_URL =
  'https://api.eu1.prepr.io/sign_in/google/961692789316-03nnfreko6amj6l85beiio7h6ea3nibg.apps.googleusercontent.com';

export const FB_APP_ID = '257685902519321';
export const FB_CLIENT_TOKEN = '257685902519321';

export const NEWS_SIDEBAR_ID = '3c4c5760-8dfd-40ba-bd17-27c89df0edc3';
export const TIP_SIDEBAR_ID = 'ed2747eb-eddc-4623-907f-39b021d388ba';

export const FORGOT_PASSWORD_PAGE_ID = '354fc18f-9531-43f7-accb-c961ee754dab';
export const RESET_PASSWORD_PAGE_ID = '41676eca-41ba-4a53-a1d2-13debbdab123';
export const SET_PASSWORD_PAGE_ID = '61ae7ce1-08f4-4ee2-a0d3-c2659ecf72ee';

export const REVALIDATION_TIME = 900;
export const XL_REVALIDATION_TIME = 86400;

export const NL_DOMAIN = 'https://www.droomplekken.nl';
export const EN_DOMAIN = 'https://www.amazingplaces.com';

export const NL_TRAVELOFFERS_DOMAIN = 'https://reisaanbod.droomplekken.nl';

export const NL_SHAREAHOLIC_ID = '9646788fadfcd6c02ba223126a142fee';
export const EN_SHAREAHOLIC_ID = '65e13b9a9d1a4b9cd5ec07cd2c03913b';
