export const NearbyTipsFields = `
{
    title

    button {
      url
      body
    }
}
`;
