import { IAdvertisementHalfElement } from './AdvertisementHalf/types';
import { IAssetElement } from './Asset/types';
import { IBucketlistHeaderElement } from './BucketlistHeader/types';
import { ICategoryBannerElement } from './CategoryBanner/types';
import { IContactBlockElement } from './ContactBlock/types';
import { IDividerElement } from './Divider/types';
import { IFacebookPostElement } from './FacebookPost/types';
import { IFaqBlockElement } from './FaqBlock/types';
import { IFaqItemElement } from './FaqItem/types';
import { IHTMLCodeElement } from './HTMLCode/types';
import { IInlineImageElement } from './InlineImage/types';
import { IInstagramContentElement } from './InstagramPost/types';
import { INearbyTipsElement } from './NearbyTips/types';
import { IPartnersElement } from './Partners/types';
import { IPhotoAlbumElement } from './PhotoAlbum/types';
import { IQuoteElement } from './Quote/types';
import { IResourceLinkElement } from './ResourceLink/types';
import { ISponsoredTraveltipBlockElement } from './SponsoredTraveltipBlock/types';
import { ITextElement } from './Text/types';
import { ITravelResourceGroupElement } from './TravelResourceGroup/types';
import { ITravelStoryElement } from './TravelStory/types';
import { ITravelSuggestionElement } from './TravelSuggestion/types';
import { ITravelTipsElement } from './TravelTips/types';
import { IUspBlockElement } from './UspBlock/types';
import { IYoutubePostElement } from './YoutubePost/types';

export enum ELEMENT_TYPES {
  CATEGORY_BANNER = 'CategoryBannerElement',
  NEARBY_TIPS = 'NearbyTips',
  SIGHT_BLOCK = 'SightBlock',
  TRAVEL_SUGGESTION = 'TravelSuggestion',
  TRAVEL_TIPS = 'TravelTips',
  TRAVEL_STORY = 'TravelstoryLink',
  TEXT = 'Text',
  ASSETS = 'Assets',
  DIVIDER = 'Divider',
  PHOTO_ALBUM = 'PhotoAlbum',
  ABOUT_ELEMENT = 'AboutCornoElement',
  SIDEBAR = 'Sidebar',
  ABOUT_CORNO = 'AboutCorno',
  NEWSLETTER_FORM = 'SmallNewsletterForm',
  INLINE_IMAGE = 'InlineImage',
  PARTNERS = 'Partners',
  PARTNER = 'Partner',
  FAQ_BLOCK = 'FaqBlock',
  FAQ_ITEM = 'FaqItem',
  CONTACT_BLOCK = 'ContactBlock',
  USP_BLOCK = 'USPBlock',
  USP_ITEM = 'USPItem',
  BUCKETLIST_HEADER = 'BucketlistHeader',
  YOUTUBE = 'YouTubePost',
  ARTICLES_BLOCK = 'NewsArticlesBlock',
  VIDEOS_BLOCK = 'VideosBlock',
  TRAVELSTORIES_BLOCK = 'TravelstoriesBlock',
  TRAVEL_RESOURCE_GROUP = 'TravelresourceGroup',
  SPONSORED_TRAVELTIP_BLOCK = 'SponsoredTravelTipBlock',
  RESOURCE_LINK = 'ResourceLink',
  ADVERTISEMENT_HALF = 'AdvertisementHalf',
  QUOTE = 'Quote',
  HTML_CODE = 'HTMLCode',
  FACEBOOK_POST = 'FacebookPost',
  INSTAGRAM_POST = 'InstagramPost',
}

export interface ICategory {
  body: string;
}

export type TContentElement =
  | ITextElement
  | IAssetElement
  | INearbyTipsElement
  | ITravelStoryElement
  | ITravelSuggestionElement
  | ITravelTipsElement
  | ICategoryBannerElement
  | IDividerElement
  | IPhotoAlbumElement
  | IInlineImageElement
  | IPartnersElement
  | IFaqBlockElement
  | IFaqItemElement
  | IContactBlockElement
  | IUspBlockElement
  | IBucketlistHeaderElement
  | IYoutubePostElement
  | ITravelResourceGroupElement
  | ISponsoredTraveltipBlockElement
  | IResourceLinkElement
  | IQuoteElement
  | IHTMLCodeElement
  | IFacebookPostElement
  | IInstagramContentElement
  | IAdvertisementHalfElement;
