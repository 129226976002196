import { AssetFields } from '../Asset/query';

export const AboutCornoElementFields = `
{
    display_title
    description
    button_url
    button_label
    background_image ${AssetFields(800)}
}
`;

export const AboutCornoFields = `
{
    about_corno_element ${AboutCornoElementFields}
}
`;
