import { AssetFields } from '../Asset/query';

export const TravelTipsElementFields = `
{
    display_title
    photo ${AssetFields(400)}
    description
    first_column
    second_column
}
`;
